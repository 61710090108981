// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-5f0ee534]{font-family:\"Open Sans\"}.font-header[data-v-5f0ee534]{font-family:\"Hero\"}.bank-cooperations .bank-cooperations-title[data-v-5f0ee534]{text-align:center;margin-bottom:2rem}@media screen and (min-width:600px){.bank-cooperations .bank-cooperations-title[data-v-5f0ee534]{margin-bottom:3rem}}@media screen and (min-width:pad){.bank-cooperations .bank-cooperations-title[data-v-5f0ee534]{margin-bottom:3rem}}.bank-cooperations div.bank-cooperations-box[data-v-5f0ee534]{display:flex;flex-wrap:wrap;max-width:100%;justify-content:flex-start;align-items:center;text-align:center;grid-row-gap:2rem;row-gap:2rem}@media screen and (min-width:600px){.bank-cooperations div.bank-cooperations-box[data-v-5f0ee534]{justify-content:center;grid-row-gap:3rem;row-gap:3rem}}@media screen and (min-width:pad){.bank-cooperations div.bank-cooperations-box[data-v-5f0ee534]{justify-content:center;grid-row-gap:3rem;row-gap:3rem}}.bank-cooperations div.bank-cooperations-box div[data-v-5f0ee534]{flex:0 0 auto;width:50%}@media screen and (min-width:600px){.bank-cooperations div.bank-cooperations-box div[data-v-5f0ee534]{width:33.33333333%}}@media screen and (min-width:pad){.bank-cooperations div.bank-cooperations-box div[data-v-5f0ee534]{width:33.33333333%}}.bank-cooperations div.bank-cooperations-box div .bank-cooperations-img[data-v-5f0ee534]{-o-object-fit:contain;object-fit:contain;max-width:75%}@media screen and (min-width:600px){.bank-cooperations div.bank-cooperations-box div .bank-cooperations-img[data-v-5f0ee534]{max-width:100%}}@media screen and (min-width:pad){.bank-cooperations div.bank-cooperations-box div .bank-cooperations-img[data-v-5f0ee534]{max-width:100%}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
