// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-96676cfa]{font-family:\"Open Sans\"}.font-header[data-v-96676cfa]{font-family:\"Hero\"}section[data-v-96676cfa] .form-container{margin-top:40px}section[data-v-96676cfa] .form-container .btn{max-width:300px}section[data-v-96676cfa] .form-success{box-sizing:border-box;background:#f9fafc}@media screen and (max-width:600px){section[data-v-96676cfa] .form-success{padding:32px}}@media screen and (min-width:600px){section[data-v-96676cfa] .form-success{padding:32px}}@media screen and (min-width:900px){section[data-v-96676cfa] .form-success{padding:40px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
