<template>
    <newsletter-form 
        :header="slice.primary.h3" 
        :teaser="slice.primary.p" 
        :footer-text="slice.primary.footer_p"
        :image="slice.primary.img"
    />
</template>
<script>
import NewsletterForm from '@/components/newsletter-form/index.vue';
export default {
    components: {
        NewsletterForm
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>