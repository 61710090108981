// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-7b2fa675]{font-family:\"Open Sans\"}.font-header[data-v-7b2fa675]{font-family:\"Hero\"}.search[data-v-7b2fa675]{position:relative;z-index:2}.search .open-search-button[data-v-7b2fa675]{white-space:nowrap}.search-open[data-v-7b2fa675]{width:100vw;height:100vh;position:fixed;left:0;top:0}.search-open__popup[data-v-7b2fa675]{max-width:100%;width:400px;background:#fff;border:0;box-sizing:border-box;border:1px solid rgba(241,214,245,.7);border-radius:20px;z-index:1;position:absolute;left:50%;top:150px;transform:translateX(-50%)}@media screen and (max-width:600px){.search-open__popup[data-v-7b2fa675]{width:95%}}@media screen and (min-width:mobile){.search-open__popup[data-v-7b2fa675]{width:95%}}@media screen and (max-width:600px){.search-open__popup[data-v-7b2fa675]{padding:16px}}@media screen and (min-width:600px){.search-open__popup[data-v-7b2fa675]{padding:16px}}@media screen and (min-width:900px){.search-open__popup[data-v-7b2fa675]{padding:20px}}.search-open__popup input[data-v-7b2fa675]{width:100%;margin-bottom:10px}.search-open__popup .search-result-wrapper>div[data-v-7b2fa675]{max-height:320px;overflow:auto}.search-open__popup .search-result-wrapper .no-results[data-v-7b2fa675]{padding:10px;font-size:14px;line-height:22px}.search-open__overlay[data-v-7b2fa675]{position:fixed;width:100%;height:100%;top:0;left:0;background:rgba(0,0,0,.5)}.fade-enter-active[data-v-7b2fa675],.fade-leave-active[data-v-7b2fa675]{transition:opacity .3s}.fade-enter[data-v-7b2fa675],.fade-leave-to[data-v-7b2fa675]{opacity:0}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
