<template>
    <Section
        class="banner"
        width="lg"
        :class="{'with-author': hasAuthor}"
    >
        <div class="content-container">
            <div class="container-left">
                <div class="container-inner">
                    <h1>{{ $prismic.asText(slice.primary.h1) }}</h1>
                    <p v-if="$validateText(slice.primary.teaser)">
                        {{ $prismic.asText(slice.primary.teaser) }}
                    </p>
                </div>

                <author-and-editor :document="document" />
            </div>

            <div class="container-right">
                <div class="container-inner">
                    <nuxt-link :to="$prismic.linkResolver(card)">
                        <prismic-image
                            :img="card.data.image"
                            w="350"
                            class="card-img"
                            :lazy="false"
                        />
                    </nuxt-link>

                    <span class="font-header">{{ card.data.title }}</span>

                    <p 
                        v-if="$validateText(slice.primary.card_teaser)"
                        class="rich-text card-teaser"
                    >
                        {{ $prismic.asText(slice.primary.card_teaser) }}
                    </p>
                    <ButtonTarget
                        :link="card"
                        background="orange"
                        size="md"
                    />
                </div>
                <div v-if="model.complianceExample" class="text-compliance">
                    {{ model.complianceExample }}
                </div>
                <CreditWarning v-if="$isSweden() && !model.isDebit" />
            </div>
        </div>
    </Section>
</template>

<script>
import AuthorAndEditor from '@/components/AuthorAndEditor.vue';
import CreditWarning from '@/components/CreditWarning.vue';
export default {
    components: { AuthorAndEditor, CreditWarning },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        },
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.slice.primary.featured_card.id];
        },
        model() {
            return new this.$models.CreditCard(this.card, this.$store);
        },
        hasAuthor() {
            return this.document.data.post_author.id ?? null;
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $graybg;
    .container-inner {
        margin: 0;
    }
    .content-container {
        @include grid(1, 30px);
        @media screen and (min-width: 768px) {
            @include grid(2, 60px);
        }

        .container-left {
            @include flex();
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            @media screen and (min-width: 768px) {
                gap: 30px;
            }

            .container-inner {
                @include grid(1,30px);
            }

        }
        .container-right {
            position: relative;
            @include flex(start);
            @media screen and (min-width: 768px) {
                @include flex;
            }


            .container-inner {
                @include spacing(padding,6);
                @include border-radius(20px);
                box-shadow: $bxs;
                @include border;
                background: $white;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                @media screen and (min-width: 768px) {
                    gap: 30px;
                }

                img {
                    @include border-radius(15px);
                }

                .font-header {
                    text-align: center;
                    @include font-size(24px);
                }

                .card-teaser {
                    text-align: center;
                    @include font-size(16px);
                }

                .btn-div {
                    width: 100%;
                }
            }
            .text-compliance {
                box-sizing: border-box;
                max-width: 410px;
                padding: 10px ;
            }
        }
    }
}

.credit-warning {
    margin-top: 20px;
}
</style>
