<template>
    <Section v-if="items.length > 0" width="md">
        <div v-if="$validateText(slice.primary.header)" class="rich-text" v-html="$prismic.asHtml(slice.primary.header)" />
        <ul>
            <li v-for="item in items" :key="item.id">
                <nuxt-link :to="$prismic.linkResolver(item.document)">
                    <prismic-image v-if="item.image.url" :img="item.image" :alt="item.image.alt" w="545" />
                    <span>{{ item.title }}</span>
                </nuxt-link>
            </li>
        </ul>
    </Section>
</template>

<script>
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            items: []
        };
    },
    async fetch() {
        const prismicDocuments = await this.$prismic.api.getByIDs(
            this.ids,
            { lang: process.env.PRISMIC_LOCALE }
        );
        this.items = this.slice.items
            .map(item => {
                const matchingDoc = prismicDocuments.results.find(doc => doc.id === item.document.id);
                const title = this.$validateText(item.title) ? item.title : matchingDoc?.data?.title;
                return {
                    document: item.document,
                    image: matchingDoc.data.image,
                    title: replace(title)
                };
            })
            .filter(item => item.title);
    },
    computed: {
        ids() {
            return this.slice.items
                .filter(item => this.$validateContentRelationship(item.document))
                .map(item => item.document.id);
        }
    },
};
</script>

<style lang="scss" scoped>
ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include device(pad) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include device(desktop) {
        grid-template-columns: repeat(3, 1fr);
    }

    li {
        a {
            display: flex;
            flex-direction: column;
            @include border-radius(20px);
            box-shadow: $bxs;
            width: 100%;
            height: 100%;
            align-self: center;
            position: relative;

            &:hover {
                color: $green;
            }

            img {
                width: 100%;
                @include border-radius(20px,(top-left,top-right));
            }

            span {
                @include spacing(padding, 4);
                @include font-size(16px);
                font-weight: bold;
            }
        }     
    }
}
</style>