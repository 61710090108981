<template>
    <Section width="md">
        <div
            v-if="$prismic.asHtml(slice.primary.teaser).length"
            class="rich-text"
            v-html="$prismic.asHtml(slice.primary.teaser)"
        />

        <div class="grid">
            <card
                v-for="(card, index) in cards"
                :id="card.id"
                :key="index"
            />
        </div>
    </Section>
</template>

<script>
import Card from './HighlightedCardsCard.vue';
export default {
    components: { Card },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        cards() {
            return this.slice.items
                .filter(item => this.$validateContentRelationship(item.card))
                .map(item => item.card);
        }
    }
};
</script>

<style lang="scss" scoped>
    section {
        .grid {
            @include grid(1,80px);
            @include spacing(margin,6,top);
        }
    }
</style>
