// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-7ac4a3ec]{font-family:\"Open Sans\"}.font-header[data-v-7ac4a3ec]{font-family:\"Hero\"}.card-tile[data-v-7ac4a3ec]{width:100%;min-width:230px;height:52px;display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center;flex-wrap:nowrap;grid-gap:.5rem;gap:.5rem;padding:10px;border:0;box-sizing:border-box;border:1px solid rgba(241,214,245,.7);border-radius:10px;box-shadow:0 10px 15px rgba(169,177,200,.25);cursor:pointer;transition:transform .2s}@media screen and (min-width:600px){.card-tile[data-v-7ac4a3ec]:not(.column){max-width:250px}}@media screen and (min-width:pad){.card-tile[data-v-7ac4a3ec]:not(.column){max-width:250px}}@media screen and (min-width:900px){.card-tile[data-v-7ac4a3ec]:not(.column){max-width:280px}}.card-tile[data-v-7ac4a3ec]:hover{transform:scale(1.02);transition:transform .2s}.card-tile .image[data-v-7ac4a3ec]{height:100%;max-width:45px}.card-tile .title[data-v-7ac4a3ec]{flex:1;font-weight:700;font-size:14px;white-space:wrap}.card-tile .title.long-title[data-v-7ac4a3ec]{min-width:151px}.card-tile i[data-v-7ac4a3ec]:not(.fa-clear){color:#01685e}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
