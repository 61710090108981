<template>
    <li class="item-container">
        <div class="header-section">
            <nuxt-link class="image-container" :to="$prismic.linkResolver(card)">
                <prismic-image 
                    :img="card.data.image"
                    :alt="card.data.title"
                    w="200"
                />
            </nuxt-link>
            <h3 class="h2 header">{{ title }}</h3>
            <div 
                class="rich-text" 
                v-html="$prismic.asHtml(item.text)"
            />
        </div>
        <data-table 
            v-if="dataType === 'card-data'" 
            :model="model" 
        />
        <pros-list 
            v-else 
            :pros="pros"
        />
        <div v-if="model.complianceExample" class="text-compliance">
            {{ model.complianceExample }}
        </div>
        <div class="button-container">
            <ButtonTarget
                :link="card"
                background="orange"
                size="md"
            />
            <nuxt-link 
                :to="$prismic.linkResolver(card)"
                class="read-more-button btn btn-md"    
            >
                {{ $translate('cta_read_more', 'Läs mer') }}
            </nuxt-link>
        </div>
    </li>
</template>

<script>
import DataTable from './DataTable.vue';
import ProsList from './ProsList.vue';
export default {
    components: {
        DataTable,
        ProsList
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        dataType: {
            type: String,
            required: true,
        },
        categoryDataKey: {
            type: String,
            required: false,
            default: 'general'
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.item.card.id];
        },
        model() {
            return new this.$models.CreditCard(this.card, this.$store);
        },
        title() {
            if (this.$validateText(this.item.title)) {
                return this.item.title.replace('{index}', this.index);
            }
            return this.index + '. ' + this.card.data.title;
        },
        pros() {
            if (this.$validateText(this.item.pros)) {
                return this.item.pros;
            }
            return this.$getCategoryData(this.card, this.categoryDataKey).pros; 
        }
    }
};
</script>

<style lang="scss" scoped>
    .item-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .header-section {
            @include device(mobile) {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
            }
            .header {
                @include spacing(margin, 4, bottom);
                order: -1;
            }
            .image-container { 
                @include device(pad) {
                    float: right;
                    margin-left: 20px;
                    img {
                        width: 150px;
                    }
                }
            }
        }
        .button-container::v-deep {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap-reverse;
            gap: 1rem;

            .btn-div {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                @include device(pad) {
                    flex-grow: 0;
                }

                a.btn {
                    border: 2px solid $orange;
                    &:hover { 
                        border-color: $grayblue;
                    }
                }
            }

            .read-more-button {
                display: flex;
                justify-content: center;
                flex-grow: 1;
                box-shadow: $bxs;
                background-color: $lightgray;
                color: $green;
                border: 2px solid $green;
                &:hover {
                    background-color: $green;
                    color: $white;
                }
                @include device(pad) {
                    flex-grow: 0;
                }
            }
        }
    }
</style>