<template>
    <Section width="md">
        <h2 
            v-if="$validateText(slice.primary.h2)" 
            class="section-header"
        >
            {{ $prismic.asText(slice.primary.h2) }}
        </h2>
        <div 
            v-if="$validateText(slice.primary.text)"
            class="rich-text" 
            v-html="$prismic.asHtml(slice.primary.text)" 
        />
        <top-list 
            v-if="slice.primary.show_toplist" 
            :items="slice.items" 
        />
        <ol class="cards-container">
            <big-list-item 
                v-for="(item, index) in slice.items"
                :key="item.card.id"
                :item="item"
                :index="index+1"
                :data-type="slice.primary.data_box_type"
                :category-data-key="categoryDataKey"
            />
        </ol>
    </Section>
</template>

<script>
import BigListItem from '@/components/big-list/BigListItem.vue';
import TopList from '@/components/big-list/TopList.vue';
export default {
    components: {
        BigListItem,
        TopList
    },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    },
    computed: {
        categoryDataKey() {
            return this.slice.primary.category_data_key;
        }
    }
};
</script>

<style lang="scss" scoped>
    .cards-container {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }
    .section-header {
        @include spacing(margin, 4, bottom);
    }
</style>