// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-48191a62]{font-family:\"Open Sans\"}.font-header[data-v-48191a62]{font-family:\"Hero\"}.container .card-container[data-v-48191a62]{display:grid;grid-template-columns:repeat(1,1fr);grid-gap:2rem;gap:2rem;margin-top:2rem}@media screen and (min-width:600px){.container .card-container[data-v-48191a62]{grid-template-columns:repeat(2,1fr)}}@media screen and (min-width:pad){.container .card-container[data-v-48191a62]{grid-template-columns:repeat(2,1fr)}}.container .card-container .card[data-v-48191a62]{grid-gap:.5rem;gap:.5rem;display:flex;flex-direction:column;padding:2rem;box-shadow:0 .375rem 1.5rem 0 rgba(140,152,164,.125)}.container .card-container .card .card-header[data-v-48191a62]{display:flex;grid-gap:.3rem;gap:.3rem}.container .card-container .card .card-header .card-header-info[data-v-48191a62]{display:flex;flex-direction:column;grid-row-gap:.375rem;row-gap:.375rem;justify-content:center}.container .card-container .card .card-header .card-header-info .card-header-name[data-v-48191a62]{font-weight:700}.container .card-container .card .card-header .card-header-info .card-header-name[data-v-48191a62]:hover{color:#01685e;text-decoration:underline}.container .card-container .card .card-header .card-header-info .card-header-role[data-v-48191a62]{font-weight:lighter;color:#8c98a4;font-size:.875em}.container .card-container .card .email[data-v-48191a62]:hover{color:#01685e}.container .card-container .card .email i[data-v-48191a62]{color:#01685e;margin-right:5px}.container .card-container .card .card-body[data-v-48191a62]{margin-top:.5rem;border-top:.0625rem solid rgba(33,50,91,.1);padding-top:1rem}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
