<template>
    <Section width="md">
        <div class="container">
            <section-header
                :title="slice.primary.header"
                :teaser="slice.primary.teaser"
            />
            <div class="dropdown-container">
                <expand
                    v-for="item in items" 
                    :key="item.id" 
                    :button="item.expander_title"
                    class="content-expander"
                >
                    <div v-if="$validateText(item.expander_text)" class="rich-text" v-html="$prismic.asHtml(item.expander_text)" />
                </expand>
            </div>
        </div>
    </Section>
</template>

<script>
import Expand from '@/components/slices/list/list-item/list-item-components/Expand.vue';

export default {
    components: {
        Expand
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return ({
            items: this.slice.items
        });
    },
};
</script>

<style lang="scss" scoped>
.dropdown-container::v-deep {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .content-expander {
        border: none;

        .expand-row__button {
            background-color: $graybg;
            border-radius: 25px;
            justify-content: flex-start;
            gap: 0.5rem;
            flex-wrap: nowrap;
            @include font-size(18px);
            @include spacing(padding, 3, (left, right));
            @include spacing(padding, 4, (top, bottom));
            transition: border-radius 0.3s 0.3s;

            @include device(pad) {
                gap: 1rem;
                @include spacing(padding, 4);
            }
            
            i.right-icon {
                margin-left: auto;
                margin-right: 0.25rem;
            }

            &.expand {
                color: $green;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                transition-delay: 0s;
            }
        }

        .expand-row__content {
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
            box-shadow: $bxs;
            @include spacing(padding, 3, (left, right));

            @include device(pad) {
                @include spacing(padding, 4, (left, right));
            }

            p {
                @include font-size(16px,10px);
            }
        }

        .rich-text {
            @include spacing(padding, 4, (top, bottom));

            :first-child {
                margin-top: 0;
            }
            :last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>