<template>
    <Section width="lg">
        <div class="add-cards">
            <search-cards @toggle-card="toggleCard" />
            <suggested-cards @toggle-card="toggleCard" />
        </div>

        <div
            v-if="showCardList"
            class="table-container mobile-scroll-shadow"
        >
            <table class="table-container__table">
                <tr>
                    <td class="hide-content-mobile" />
                    <td
                        v-for="(id, index) in cardList"
                        :id="id"
                        :key="index"
                        class="card-img"
                    >
                        <nuxt-link :to="$prismic.linkResolver(card(id))">
                            <prismic-image
                                :img="card(id).data.image"
                                w="80"
                                h="50"
                            />
                        </nuxt-link>
                        <div class="container">
                            <span class="font-header">{{ card(id).data.title }}</span>
                            <i class="fa-regular fa-xmark fa-clear" @click="removeCardFromQuery(id)" />
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('annual_fee', 'Årsavgift') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('annual_fee', 'Årsavgift') }}
                        </span>
                        <span v-html="model(id).yearlyCostHtml" />
                        <first-year-offer-tooltip :model="model(id)" />
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('max_credit', 'Högsta Kredit') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('max_credit', 'Högsta Kredit') }}
                        </span>
                        <div>
                            {{ model(id).getMaxCreditString($translate('unlimited_max_credit', 'Obegränsad')) }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('interest', 'Ränta') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('interest', 'Ränta') }}
                        </span>
                        <div>
                            {{ model(id).interestString }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('effective_interest', 'Effektiv ränta') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('effective_interest', 'Effektiv ränta') }}
                        </span>
                        <div>
                            {{ model(id).effectiveInterestString }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('interest_free', 'Räntefritt') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('interest_free', 'Räntefritt') }}
                        </span>
                        <div>
                            {{
                                model(id).getInterestFreeDaysString($translate('days', '{days} dagar'))
                            }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('card_type', 'Korttyp') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('card_type', 'Korttyp') }}
                        </span>
                        <div>
                            {{ $getCardTypeString(model(id).cardType) }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('age_limit', 'Åldersgräns') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('age_limit', 'Åldersgräns') }}
                        </span>
                        <div>
                            {{ model(id).minAge }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('compare_cards_required_income_per_month', 'Inkomstkrav per månad') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('compare_cards_required_income_per_month', 'Inkomstkrav per månad') }}
                        </span>
                        <div>
                            {{ $format.currency(model(id).minimumYearlyIncome / 12) }}
                        </div>
                    </td>
                </tr>

                <tr>    
                    <td class="hide-content-mobile">
                        {{ $translate('currency_surcharge', 'Valutapåslag') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('currency_surcharge', 'Valutapåslag') }}
                        </span>
                        <div>
                            {{ model(id).getCurrencyExchangeFeeString() }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('withdraw_fee', 'Uttagsavgift') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('withdraw_fee', 'Uttagsavgift') }}
                        </span>
                        <div>
                            {{ model(id).getWithdrawalFeeString() }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('compare_cards_min_amount_to_pay', 'Minsta belopp att betala') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('compare_cards_min_amount_to_pay', 'Minsta belopp att betala') }}
                        </span>
                        <div>
                            {{ model(id).getInvoiceMinToPayString() }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('payment_remarks', 'Betalningsanmärkningar') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('payment_remarks', 'Betalningsanmärkningar') }}
                        </span>
                        <div>
                            {{ $formatBoolean(model(id).acceptsPaymentRemarks) }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('avi_fee', 'Aviavgift') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('avi_fee', 'Aviavgift') }}
                        </span>
                        <div>
                            {{ model(id).getAviFeeString() }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('late_payment_fee', 'Förseningsavgift') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('late_payment_fee', 'Förseningsavgift') }}
                        </span>
                        <div>
                            {{ model(id).latePaymentFeeString }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('penalty_interest', 'Dröjmålsränta') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('penalty_interest', 'Dröjmålsränta') }}
                        </span>
                        <div>
                            {{ model(id).penaltyInterestString }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('overdraft_fee', 'Övertrasseringsavgift') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('overdraft_fee', 'Övertrasseringsavgift') }}
                        </span>
                        <div>
                            {{ model(id).overdraftFeeString }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('insurances', 'Försäkringar') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('insurances', 'Försäkringar') }}
                        </span>
                        <ul>
                            <template v-for="(insurance, insuranceIndex) in insurances(id)">
                                <li
                                    v-if="insurance.value"
                                    :key="insuranceIndex"
                                >
                                    <i class="fa-solid fa-check-circle fa-check_circle" />
                                    {{ insurance.text }}
                                </li>
                            </template>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('bonus_and_benefits', 'Bonus & Förmåner') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('bonus_and_benefits', 'Bonus & Förmåner') }}
                        </span>
                        <ul>
                            <li
                                v-for="(bonusItem, bonusIndex) in bonus(id)"
                                :key="bonusIndex"
                            >
                                <i class="fa-solid fa-check-circle fa-check_circle" />
                                {{ bonusItem.text }}
                            </li>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('mobile_payments', 'Mobila betalningsmetoder') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span v-if="paymentMethods(id).length" class="mobile-data-title">
                            {{ $translate('mobile_payments', 'Mobila betalningsmetoder') }}
                        </span>
                        <ul>
                            <li v-for="(paymentMethod, methodIndex) in paymentMethods(id)" :key="methodIndex">
                                <i class="fa-solid fa-check-circle fa-check_circle" />
                                {{ paymentMethod.text }}
                            </li>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('interest_example', 'Ränteexempel') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <span class="mobile-data-title">
                            {{ $translate('interest_example', 'Ränteexempel') }}
                        </span>
                        <div v-if="model(id).complianceExample" class="compliance">
                            {{ model(id).complianceExample }}
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile">
                        {{ $translate('cta_review', 'Recension') }}
                    </td>
                    <td
                        v-for="(id, index) in cardList"
                        :key="index"
                    >
                        <nuxt-link 
                            class="review" 
                            :to="$prismic.linkResolver(card(id))"
                        >
                            {{ $translate('cta_review', 'Recension') }}
                        </nuxt-link>
                    </td>
                </tr>

                <tr>
                    <td class="hide-content-mobile" />
                    <td
                        v-for="(id, index) in cardList"
                        :id="id"
                        :key="index"
                    >
                        <ButtonTarget
                            :text="$translate('cta_apply', 'Ansök')"
                            :link="card(id)"
                            size="sm"
                            background="orange"
                            class="apply-now-container"
                        />
                    </td>
                </tr>
            </table>
        </div>

        <div
            v-else
            class="table-container__empty"
        >
            <h3>{{ $translate('compare_cards_no_cards_to_compare', 'Du har inte valt några kort att jämföra.') }}</h3>
            <p>{{ $translate('compare_cards_press_the_button_above_to_add_a_card', 'Klicka på knappen ovanför för att lägga till kort.') }}</p>
        </div>
    </Section>
</template>

<script>
import SearchCards from '@/components/compare-cards/SearchCards.vue';
import FirstYearOfferTooltip from '@/components/card/FirstYearOfferTooltip.vue';
import SuggestedCards from '@/components/compare-cards/SuggestedCards.vue';
export default {
    components: {
        SearchCards,
        FirstYearOfferTooltip,
        SuggestedCards
    },
    computed: {
        cardList() {
            let query = this.$route.query.card;

            if (typeof query === 'string') {
                return [query];
            }

            return query;
        },
        showCardList() {
            if (this.cardList === undefined || this.cardList.length === 0) {
                return false;
            }
            return true;
        }
    },
    watch: {
        cardList() {
            this.$event.compareCards(this.cardList);
        }
    },
    mounted() {
        this.$store.commit('CLEAR_CARDS_TO_COMPARE');
        this.$event.compareCards(this.cardList);
    },
    methods: {
        bonus(id) {
            let card = this.$store.state.cards[id];
            let model = new this.$models.CreditCard(card, this.$store);
            return [
                {
                    text: this.$translate('bonus', 'Bonus'),
                    value: model.hasBonusProgram
                },
                {
                    text: this.$translate('concierge_service', 'Conciergetjänst'),
                    value: model.hasConciergeService
                },
                {
                    text: this.$translate('access_to_lounges', 'Tillgång till lounger'),
                    value: model.hasLoungeAccess
                },
                {
                    text: this.$translate('compare_cards_gives_cashback', 'Ger cashback'),
                    value: model.hasCashback
                },
                {
                    text: this.$translate('free_extra_card', 'Gratis extra kort'),
                    value: model.hasFreeExtraCard
                }
            ].filter(item => item.value);
        },
        insurances(id) {
            let card = this.$store.state.cards[id];
            let model = new this.$models.CreditCard(card, this.$store);
            return [
                {
                    text: this.$translate('payment_protection', 'Betalskydd'),
                    value: model.hasPurchaseProtection
                },
                {
                    text: this.$translate('delivery_insurance', 'Leveransförsäkring'),
                    value: model.hasDeliveryInsurance
                },
                {
                    text: this.$translate('travel_insurance', 'Reseförsäkring'),
                    value: model.hasTravelInsurance
                },
                {
                    text: this.$translate('price_guarantee', 'Prisgaranti'),
                    value: model.hasPriceGuarantee
                },
                {
                    text: this.$translate('comprehensive_insurance', 'Allriskförsäkring'),
                    value: model.hasComprehensiveInsurance
                },
                {
                    text: this.$translate('cancellation_protection', 'Avbeställningsskydd'),
                    value: model.hasCancellationProtection
                },
                {
                    text: this.$translate('deductible_reduction', 'Självriskreducering'),
                    value: model.hasDeductibleInsurance
                },
                {
                    text: this.$translate('key_insurance', 'Nyckelförsäkring'),
                    value: model.hasLostKeyInsurance
                },
                {
                    text: this.$translate('wrong_fuel_insurance', 'Feltankningsförsäkring'),
                    value: model.hasWrongFuelInsurance
                }
            ];
        },
        paymentMethods(id) {
            let card = this.$store.state.cards[id];
            let model = new this.$models.CreditCard(card, this.$store);

            return [
                {
                    text: 'Apple Pay',
                    value: model.hasApplePay
                },
                {
                    text: 'Google Pay',
                    value: model.hasGooglePay
                },
                {
                    text: 'Samsung Pay',
                    value: model.hasSamsungPay
                },
            ].filter(item => item.value);
        },
        toggleCard(card) {
            let query = this.$route.query.card;

            if (query !== undefined) {
                if (!query.includes(card)) {
                    if (query.length === 0) {
                        let newQuery = [card];
                        this.$router.push({ query: Object.assign({}, this.$route.query, { card: newQuery }) });
                    }
                    else {
                        let newQuery = [query] + ',' + card;
                        let queryToPush = newQuery.split(',').join('&card=');
                        this.$router.push('?card=' + queryToPush);
                    }
                }
                else {
                    this.removeCardFromQuery(card);
                }
            }
            else {
                let newQuery = [card];
                this.$router.push({ query: Object.assign({}, this.$route.query, { card: newQuery }) });
            }
        },
        model(id) {
            let card = this.$store.state.cards[id];
            let model = new this.$models.CreditCard(card, this.$store);
            return model;
        },
        card(id) {
            return this.$store.state.cards[id];
        },
        removeCardFromQuery(card) {
            let query = this.$route.query.card;
            if (typeof query !== 'string') {
                let newQuery = query.filter(id => card !== id);
                this.$router.push({ query: Object.assign({}, this.$route.query, { card: newQuery }) });
            }
            else {
                this.$router.push({ query: Object.assign({}, this.$route.query, { card: undefined }) });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        padding-top: 0;

        .section-inner {
            width: 100%;
        }
        .add-cards {
            @include flex(start, center);
            gap: 1rem;
            flex-wrap: wrap;
            @include spacing(margin, 5, (bottom));
            
            @include device(pad)  {
                flex-wrap: nowrap;
            }
        }

        .table-container {
            @include border(top,5px,$green);
            overflow: auto;
            padding-bottom: 20px;

            &__table {
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;

                tr {
                    @include font-size(14px,6px);
                    &:nth-child(odd) {
                        background: $graybg;
                        td:first-child {
                            background: $graybg;
                        }
                    }
                    &:nth-child(even) {
                        background: $white;
                        td:first-child {
                            background: $white;
                        }
                    }

                    @include device(mobile) {
                        td:nth-child(2) {
                            border-left: solid 1px $purplebg;
                        }
                    }

                    .mobile-data-title {
                        display: block;
                        font-size: 12px;
                        font-weight: bold; 
                        @include device(pad) {
                            display: none;
                        }
                    }
                    &:has(ul) {
                        .mobile-data-title { 
                            margin-bottom: 5px;
                        }
                    }
                    .hide-content-mobile {
                        display: none;
                        @include device(pad) {
                            display: table-cell;
                        }
                    }

                    td {
                        padding: 10px;
                        border-right: solid 1px $purplebg;
                        border-bottom: solid 1px $purplebg;
                        min-width: 200px;
                        max-width:400px;
                        &:first-child {
                            font-weight: bold;
                            max-width: 200px;
                            min-width: 0;
                            position: sticky;
                            left: 0;
                            border-left: solid 1px $purplebg;
                        }

                        .apply-now-container {
                            width: fit-content;
                            a {
                                width: 200px;
                            }
                        }

                        ul {
                            @include grid(1,5px);
                        }

                        ul.table {
                            li {
                                @include device(mobile) {
                                    @include flex(between);
                                }
                                @include device(pad) {
                                    @include grid(2,20px);
                                }

                                span {
                                    &:first-child {
                                        font-weight: bold;
                                        @include device(mobile) {
                                            flex: 1;
                                        }
                                    }
                                }
                            }
                        }

                        .standard-yearly-cost {
                            color: red;
                            margin-left: 5px;
                            text-decoration: line-through;
                        }

                        .container {
                            margin-top: 10px;
                            span {
                                margin-right: 10px;
                                @include font-size(16px,8px);
                            }
                            i {
                                cursor: pointer;
                                font-size: 18px;
                            }
                        }

                        a.review {
                            color: $green;
                            &:hover { text-decoration: underline; }
                        }

                        .btn { max-width: 200px; }
                    }
                }
                
                .text-compliance {
                    max-width:380px;
                }
            }
        }
    }
</style>
