// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-88e4de7c]{font-family:\"Open Sans\"}.font-header[data-v-88e4de7c]{font-family:\"Hero\"}ul[data-v-88e4de7c]{list-style:none;display:grid;grid-template-columns:1fr;grid-gap:1rem;gap:1rem}@media screen and (min-width:600px){ul[data-v-88e4de7c]{grid-template-columns:repeat(2,1fr)}}@media screen and (min-width:pad){ul[data-v-88e4de7c]{grid-template-columns:repeat(2,1fr)}}@media screen and (min-width:900px){ul[data-v-88e4de7c]{grid-template-columns:repeat(3,1fr)}}ul li a[data-v-88e4de7c]{display:flex;flex-direction:column;border-radius:20px;box-shadow:0 10px 15px rgba(169,177,200,.25);width:100%;height:100%;align-self:center;position:relative}ul li a[data-v-88e4de7c]:hover{color:#01685e}ul li a img[data-v-88e4de7c]{width:100%;border-top-left-radius:20px;border-top-right-radius:20px}ul li a span[data-v-88e4de7c]{box-sizing:border-box;font-size:16px;line-height:22px;font-weight:700}@media screen and (max-width:600px){ul li a span[data-v-88e4de7c]{padding:16px}}@media screen and (min-width:600px){ul li a span[data-v-88e4de7c]{padding:16px}}@media screen and (min-width:900px){ul li a span[data-v-88e4de7c]{padding:20px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
