<template>
    <newsletter-form 
        :data="data"
    />
</template>
<script>
import NewsletterForm from './Form.vue';
export default {
    components: { NewsletterForm },
    props: {
        header: {
            type: Array,
            required: false,
            default: () => []
        },
        teaser: {
            type: String,
            required: false,
            default: ''
        },
        footerText: {
            type: Array,
            required: false,
            default: () => []
        },
        image: {
            type: Object,
            required: false,
            default: null
        },
    },
    computed: {
        data() {
            return {
                header: this.$validateText(this.header) 
                    ? this.$prismic.asText(this.header) 
                    : this.$translate('newsletter_header', 'Registrera dig för vårt nyhetsbrev'),
                teaser: this.$validateText(this.teaser)
                    ? this.teaser
                    : this.$translate('newsletter_p', 'Få tips, erbjudanden och mycket mer direkt på mailen.'),
                footerText: this.$validateText(this.footerText)
                    ? this.footerText
                    : this.$translate('newsletter_form_disclaimer'),
                image: this.image?.url 
                    ? this.image
                    : null
            };
        }
    },
};
</script>