<template>
    <Section
        :class="slice.slice_label"
        width="md"
    >
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />


        <ul class="card-list">
            <card
                v-for="(iterator, index) in cardItems"
                :id="iterator.card.id"
                :key="index"
                :content="iterator.content"
            />
        </ul>
        <trust-us />
    </Section>
</template>

<script>
import Card from './list-item/ListItemCardCategory.vue';
import TrustUs from '@/components/TrustUs.vue';
export default {
    components: { Card, TrustUs },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        asRichText() {
            if (this.slice.slice_label === 'in_richtext') {
                return true; 
            }
        },
        cardItems() {
            return this.slice.items
                .filter(item => this.$validateContentRelationship(item.card))
        }
    }
};
</script>

<style lang="scss" scoped>
section {

    .card-list {
        width: 100%;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        
        @include device(mobile) {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }
    }
}
</style>
