<template>
    <div class="table-container">
        <table>
            <tbody>
                <tr>
                    <th>{{ $translate('annual_fee', 'Årsavgift') }}</th>
                    <td>
                        <span v-html="model.yearlyCostHtml" />
                        <first-year-offer-tooltip :model="model" />
                    </td>
                </tr>
                <tr>
                    <th>{{ $translate('max_credit', 'Maxkredit') }}</th>
                    <td>{{ model.getMaxCreditString($translate('unlimited_max_credit', 'Obegränsad')) }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('effective_interest', 'Effektiv ränta') }}</th>
                    <td>{{ model.effectiveInterestString }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('interest_free', 'Räntefritt') }}</th>
                    <td>{{ model.getInterestFreeDaysString($translate('days', '{days} dagar')) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import FirstYearOfferTooltip from '@/components/card/FirstYearOfferTooltip.vue';
export default {
    components: {
        FirstYearOfferTooltip
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
    .table-container {
        overflow: auto;
        @include border-radius(15px);
        @include border(all,1px,$purplebg);
        box-shadow: $bxs;

        table {
            width: 100%;
            @include device(pad) {
                table-layout: fixed;
            }

            tr {
                @include border(bottom,1px,$purplebg);
                &:last-child { border: 0; }
                &:nth-child(even) {
                    background: $graybg;
                }

                th, td {
                    @include spacing(padding, 3);
                    @include font-size(16px,8px);
                    vertical-align: middle;

                    @include device(mobile) {
                        @include font-size(14px,8px);
                    }
                }
                th {
                    font-weight: bold;
                    @include border(right,1px,$purplebg);
                }
            }
        }
    }
    ::v-deep .standard-yearly-cost {
        display: inline;
        color: red;
        margin-left: 5px;
        text-decoration: line-through;
    }
</style>