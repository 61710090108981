<template>
    <Section
        :class="slice.slice_label"
        :width="slice.primary.slice_width"
    >
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <div class="table-container mobile-scroll-shadow">
            <table
                class="table-container__table"
            >
                <tr>
                    <th />
                    <th v-for="card in cards" :key="`logo${card.id}`">
                        <nuxt-link :to="$prismic.linkResolver(card)">
                            <prismic-image :img="card.data.image" w="200" />
                        </nuxt-link>
                    </th>
                </tr>
                <tr>
                    <th>{{ $translate('credit_card', 'Kreditkort') }}</th>
                    <th v-for="card in cards" :key="`title${card.id}`">{{ card.data.title }}</th>
                </tr>
                <tr>
                    <th>{{ $translate('annual_fee', 'Årsavgift') }}</th>
                    <td v-for="card in cards" :key="`fee${card.id}`">
                        <span v-html="card.model.yearlyCostHtml" />
                        <first-year-offer-tooltip :model="card.model" />
                    </td>
                </tr>
                <tr>
                    <th>{{ $translate('interest', 'Nominel ränta') }}</th>
                    <td v-for="card in cards" :key="`interest${card.id}`">{{ card.model.interestString }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('effective_interest', 'Effektiv ränta') }}</th>
                    <td v-for="card in cards" :key="`effinterest${card.id}`">{{ card.model.effectiveInterestString }}</td>
                </tr>

                <tr>
                    <th>{{ $translate('interest_free_days', 'Räntefria dagar') }}</th>
                    <td v-for="card in cards" :key="`interestfreedays${card.id}`">
                        {{ card.model.getInterestFreeDaysString($translate('days', '{days} dagar')) }}
                    </td>
                </tr>

                <tr>
                    <th>{{ $translate('currency_surcharge', 'Valutapåslag') }}</th>
                    <td v-for="card in cards" :key="`currencyexchange${card.id}`">{{ card.model.getCurrencyExchangeFeeString() }}</td>
                </tr>

                <tr>
                    <th>{{ $translate('withdraw_fee', 'Uttagsavgift') }}</th>
                    <td v-for="card in cards" :key="`withdrawal${card.id}`">{{ card.model.getWithdrawalFeeString() }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('avi_fee', 'Aviavgift') }}</th>
                    <td v-for="card in cards" :key="`avi${card.id}`">{{ card.model.getAviFeeString() }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('charatistics', 'Egenskaper') }}</th>
                    <td v-for="card in cards" :key="`prop${card.id}`">
                        <ul>
                            <li v-for="benefit in getBenefits(card.model)" :key="benefit">{{ benefit }}</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>{{ $translate('insurances', 'Försäkringar') }}</th>
                    <td v-for="card in cards" :key="`insurance${card.id}`">
                        <ul>
                            <li v-for="insurance in getInsurances(card.model)" :key="insurance">{{ insurance }}</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>{{ $translate('cta_read_more', 'Läs mer') }}</th>
                    <td v-for="card in cards" :key="`more${card.id}`">
                        <nuxt-link class="btn btn-orange-border btn-sm" :to="$prismic.linkResolver(card)">{{ $translate('cta_detailed_info', 'Detaljerad info') }}</nuxt-link>    
                    </td>
                </tr>
                <tr>
                    <th>{{ $translate('cta_apply', 'Ansök') }}</th>
                    <td v-for="card in cards" :key="`apply${card.id}`">
                        <ButtonTarget
                            :link="card"
                            background="orange"
                            size="sm"
                        />
                        <div class="text-compliance">
                            {{ card.model.complianceExample }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </Section>
</template>

<script>
import FirstYearOfferTooltip from '@/components/card/FirstYearOfferTooltip.vue';
export default {
    components: {
        FirstYearOfferTooltip
    },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        cards() {

            return this.slice.items
                .map(item => item.card)
                .filter(card => card.id)
                .map(card => {
                    const c = Object.assign({}, this.$store.state.cards[card.id]);
                    c.model = new this.$models.CreditCard(c);
                    return c;
                });
        }
    },
    methods: {
        getInsurances(model) {
            return this.filterKeys(model, {
                'hasTravelInsurance': this.$translate('travel_insurance', 'Reseförsäkring'),
                'hasPriceGurantee': this.$translate('price_guarantee', 'Prisgaranti'),
                'hasCancellationProtection': this.$translate('cancellation_protection', 'Avbeställningsskydd'),
                'hasDeductibleInsurance': this.$translate('deductible_reduction', 'Självriskreducering'),
                'hasComprehensiveInsurance': this.$translate('comprehensive_insurance', 'Allriskförsäkring'),
                'hasPurchaseProtection': this.$translate('payment_protection', 'Betalskydd'),
                'hasDeliveryInsurance': this.$translate('delivery_insurance', 'Leveransförsäkring'),
            });
        },
        getBenefits(model) {
            return [
                {
                    validate: (model) => model.hasBonusProgram,
                    value: this.$translate('bonus_program', 'Bonusprogram')
                },
                {
                    validate: (model) => model.hasCashback,
                    value: this.$translate('cashback_up_to_cashback_percentage', 'Cashback (upp till {cashbackPercentage})')
                        .replace('{cashbackPercentage}', this.$format.percentage(model.cashbackPercentage))
                },
                {
                    validate: (model) => model.hasFuelOffers,
                    value: this.$translate('petrol_discounts', 'Bensinrabatter')
                },
                {
                    validate: (model) => model.hasLoungeAccess,
                    value: this.$translate('lounge_access', 'Tillgång till lounge')
                },
                {
                    validate: (model) => model.hasConciergeService,
                    value: this.$translate('concierge_service', 'Conciergetjänst')
                },
                {
                    validate: (model) => model.hasPriorityPass,
                    value: this.$translate('priority_pass', 'Priority Pass ({freeVisits} årliga gratisbesök)')
                        .replace('{freeVisits}', model.priorityPassYearlyFreeVisits)
                },
                {
                    validate: (model) => model.hasLoungeKey,
                    value: this.$translate('lounge_key', 'Lounge key ({freeVisits} årliga gratisbesök)')
                        .replace('{freeVisits}', model.loungeKeyYearlyFreeVisits),
                },
                {
                    validate: (model) => model.currencyExchangeFeeType === 'free',
                    value: this.$translate('without_currency_exchange_fee', 'Inget valutapåslag')
                },
                {
                    validate: (model) => model.hasFreeExtraCard,
                    value: this.$translate('free_extra_card', 'Gratis extra kort')
                },
                {
                    validate: (model) => model.hasApplePay,
                    value: 'Apple Pay'
                },
                {
                    validate: (model) => model.hasGooglePay,
                    value: 'Google Pay'
                },
                {
                    validate: (model) => model.hasSamsungPay,
                    value: 'Samsung Pay'
                },
            ].filter(benefit => benefit.validate(model))
                .map(benefit => benefit.value);
        },
        filterKeys(model, map) {
            return Object.keys(map)
                .filter(key => model[key])
                .map(key => map[key]);
        }
    },
};
</script>

<style lang="scss" scoped>
    section {
        .table-container {
            overflow: auto;
            @include border-radius(15px);
            @include border(all,1px,$purplebg);
            box-shadow: $bxs;
            table {
                table-layout: fixed;  
                @include device(desktop) {
                    width: 100%;
                }              
                tr {
                    @include border(bottom,1px,$purplebg);
                    &:last-child { border: 0; }
                    &:nth-child(even) {
                        background: $graybg;
                    }

                    td, th {
                        min-width: 120px;
                        padding: 16px;
                        @include font-size(14px,8px);
                        @include border(right,1px,$purplebg);
                        &:last-child { border: 0; }
                        font-weight: normal;
                        box-sizing: content-box;
                        
                        @include device(mobile) {
                            @include font-size(12px,8px);
                            padding: 12px;
                        }

                        ul {
                            list-style: unset;
                            li {
                                font-weight: normal;
                                margin-left: 0.75em;

                                &::marker {
                                    color: $green;
                                }
                            }
                        }

                        ::v-deep a {
                            @include device(mobile) {
                                @include font-size(12px,8px);
                                padding: 10px 14px;
                            }
                        }
                         
                        ::v-deep span.standard-yearly-cost {
                            color: red;
                            margin-left: 5px;
                            text-decoration: line-through;
                        }

                        .text-compliance {
                            margin-top: 10px;
                        }
                    }
                    
                    th {
                        &:first-child {
                            min-width: 90px;
                        }
                        text-align: left;
                        font-weight: bold;
                        @include device(mobile) {
                            @include font-size(12px,8px);
                            a {
                                display: block;
                                padding: 0;
                            }
                        }

                        img {
                            width: 120px;
                            @include device(pad) {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
