<template>
    <div class="section">
        <div class="section-width-md">
            <div v-if="submitSuccess" class="success-message">
                <i class="fa-regular fa-circle-check success-icon" />
                <h5 class="success-title">
                    {{ $translate('newsletter_form_success_text', 'Du har nu anmält dig till nyhetsbrevet!') }}
                </h5>
            </div>
            <div v-else-if="loading" class="loading">
                <i class="fa-duotone fa-spinner-third fa-spin" />
            </div>
            <div 
                v-else 
                class="newsletter-bg" 
                :class="fadeoutForm ? 'fadeout-form' : 'fadein-form'"
            >
                <div class="newsletter-form">
                    <div class="newsletter-container">
                        <h3>
                            {{ data.header }}
                        </h3>
                        <p>
                            {{ data.teaser }}
                        </p>
                        <div class="newsletter-inputs-row">
                            <div class="newsletter-input-group">
                                <input 
                                    id="email"
                                    v-model="email" 
                                    class="newsletter-email" 
                                    type="email" 
                                    placeholder=" "
                                >
                                <label class="newsletter-email-label" for="email">
                                    {{ $translate('newsletter_form_email_placeholder', 'Email') }}
                                </label>
                            </div>
                            <button class="btn btn-sm btn-green newsletter-button" @click.prevent="submit">
                                {{ $translate('newsletter_form_submit_text', 'Registrera dig') }}
                            </button>
                        </div>
                        <div v-if="error" class="newsletter-error">
                            <i class="fa-solid fa-triangle-exclamation error-icon" /> 
                            {{ errorMessage }}
                        </div>
                    </div>
                    <div v-if="data.image" class="image-container">
                        <prismic-image 
                            class="newsletter-img" 
                            :img="data.image" 
                            w="125" 
                            h="175" 
                        />
                    </div>
                </div>
                <div 
                    v-if="$validateText(data.footerText)"
                    class="disclaimer rich-text" 
                    v-html="$prismic.asHtml(data.footerText)" 
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            email: '',
            submitSuccess: false,
            
            loading: false,
            error: false,
            errorMessage: null,
            preventDoubleClick: false,
            fadeoutForm: false,
        };
    },
    methods: {
        submit() {
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (this.preventDoubleClick) {
                return;
            }

            if (! this.email.match(validEmailRegex)) {
                this.errorMessage = this.$translate('newsletter_form_invalid_email', 'Ogiltig email');
                return this.error = true;
            }
            this.fadeoutForm = true;
            this.preventDoubleClick = true;
            this.loading = true;
            this.error = false;
            this.$axios
                .post('https://api.broker.compary.com/users/', {
                    email: this.email,
                    guide_id: process.env.BROKER_GUIDE_ID,
                    tracking: this.$tracking.getTrackingObject(),
                    redirect_url: null
                })
                .catch((error) => {
                    this.loading = false;
                    this.preventDoubleClick = false;
                    this.fadeoutForm = false;
                    this.error = true;
                    this.errorMessage = this.$translate('general_error_message', 'Något gick fel, försök igen senare.');
                    console.log(error);
                })
                .then(() => {
                    if (!this.error) {
                        this.email = '';
                        this.submitSuccess = true;
                        this.preventDoubleClick = true;
                        this.loading = false;
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
    .newsletter-bg {
        opacity: 1;
        background-color: $green;
        background-image: -webkit-linear-gradient(150deg, $greendark 34.9%, $green 35%);
        padding: 2rem 1.5rem;
        color: $white;
        border-radius: 24px;
        @include device(pad) {
            padding: 2rem 4rem;
        }
    }
    .fadeout-form {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fade-out;
    }
    .fadein-form {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fade-in;
    }
    .newsletter-form {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }
    .newsletter-container {
        flex-grow: 1;
        text-align: center;
        @include device(pad) {
            text-align: left;
        }
        h3 {
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 2rem;
            @include device(pad) {
               margin-bottom: 3rem;
            }
        }
    }
    .newsletter-inputs-row {
        display: flex;
        justify-content: space-between;
        background-color: $white;
        padding: 0.6125rem 0.6125rem;
        box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
        border-radius: 24rem;
        margin: 1rem 0 0 0;

        @include device(mobile) {
            flex-direction: column;
            gap: 1rem;
            padding: 0;
            background-color: transparent;
            box-shadow: none;
        }
    }
    .newsletter-input-group {
        display: flex;
        position: relative;
        flex-grow: 1;
        padding-right: 0.5rem;

        @include device(mobile) {
            background-color: $white;
            border-radius: 24rem;
            padding: 0.5rem;
        }
    }
    .newsletter-email {
        position: relative;
        border: 0;
        background-color: $white;
        flex-grow: 1;
    }
    .newsletter-email-label {
        position: absolute;
        left: 10px;
        top: 10px;
        color: #999;
        pointer-events: none;
        z-index: 9;
        transition: 0.15s ease-in-out;
        @include device(mobile) {
            top: 17.5px;
            left: 14px;
            margin-bottom: 1rem;
        }
    }
     .newsletter-email:focus ~ label, .newsletter-email:not(:placeholder-shown) ~ label {
        transform: translateX(-5%) translateY(-140%);
        font-size: .75em;
        padding: 0 0.3rem;
        @include device(mobile) {
            transform: translateX(-5%) translateY(-125%);
        }
    }
    .newsletter-email:focus-visible {
        border: 0;
    }
    .newsletter-button {
        border-radius: 24px;
        flex-shrink: 0;
    }
    .image-container {
        max-width: 100%;
        display: none;
        @include device(pad) {
            display: block;
        }
    }
    .newsletter-img {
        transform: rotate(-5deg);
    }
    .disclaimer ::v-deep p {
        margin-top: 1.5rem;
        font-size: 12px;
        font-weight: lighter;
        text-align: center;
        line-height: 0.9rem;
        color: #f8f8f8;
    }
    .disclaimer ::v-deep p > a {
        font-weight: bold;
        text-decoration: underline;
        color: $white;
    }
    .loading {
        text-align: center;
        font-size: 40px;
        color: #333;
    }
    .success-icon {
        font-size: 40px;
        margin: 0 0 1rem 0;
    }
    .success-message {
        opacity: 0;
        color: $green;
        text-align: center;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fade-in;
    }
    .error-icon {
        font-size: 18px;
    }
    .newsletter-error {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        background-color: $white;
        border-radius: 2rem;
        color: red; 
        font-size: 14px;
        padding: 10px 20px;
        margin-top: 1rem;
        @include device(pad) {
            width: fit-content;
        }
    }
    @keyframes fade-out {
        from {
            transform: translate3d(0,0,0);
        }

        to {
            transform: translate3d(0,40px,0);
            opacity: 0;
        }
    }
    @keyframes fade-in {
        from {
            transform: translate3d(0,40px,0);
            opacity: 0;
        }

        to {
            transform: translate3d(0,0,0);
            opacity: 1;
        }
    }
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
 
    transition: background-color 5000s ease-in-out 0s;
}
</style>