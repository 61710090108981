<template>
    <div v-if="cards.length > 0" :class="['card-tile-wrapper', {'column': displayAsColumn}]">
        <ul :class="['card-tile-list', {'column': displayAsColumn}]">
            <li 
                v-for="card in cards" 
                :key="card.id" 
                @click="$emit('toggle-card', card.id)"
            >
                <card-tile :card="card" :display-as-column="displayAsColumn" />
            </li>
        </ul>
    </div>
</template>

<script>
import CardTile from './CardTile.vue';
export default {
    components: {
        CardTile
    },
    props: {
        cards: {
            type: Array,
            required: false,
            default: () => []
        },
        displayAsColumn: {
            type: Boolean,
            default: false
        },
    },
};
</script>

<style lang="scss" scoped>
.card-tile-wrapper {
    width: 100%;
    overflow: hidden;
    @include spacing(padding,2);
    @include spacing(padding,5,(bottom));
    @include device(pad) {
        &:not(.column) {  
            width: fit-content;
            @include spacing(padding,4,(top,bottom));
            @include spacing(margin,-4,(top,bottom));
        }
    }
    &.column {
        @include spacing(padding,2);
        @include spacing(padding,4,(bottom));
        @include device(mobile) {
            overflow: hidden;
            overflow-y: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;   /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .card-tile-list {
        width: 100%;
        height:100%;
        @include flex(start, stretch);
        flex-direction: column;
        gap: 0.5rem;
        flex-wrap: nowrap;
        @include device(pad) {
            &:not(.column) {  
                width: fit-content;  
                flex-direction: row;
            }
        }
    }
}
</style>
