<template>
    <Section width="md">
        <div class="container">
            <section-header
                :title="slice.primary.header"
                :teaser="slice.primary.teaser"
            />
            <div class="dropdown-container">
                <expand
                    v-for="author in authors" 
                    :key="author.id"
                    :button="author.data.name"
                    class="author-expander"
                >
                    <template v-if="author.data.image" #image>
                        <prismic-image
                            :img="author.data.image"
                            w="60"
                            h="60"
                        />
                    </template>
                    <div v-if="$validateText(author.authorText)" class="rich-text" v-html="$prismic.asHtml(author.authorText)" />
                </expand>
            </div>
        </div>
    </Section>
</template>

<script>
import Expand from '@/components/slices/list/list-item/list-item-components/Expand.vue';

export default {
    components: {
        Expand
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return ({
            items: this.slice.items
        });
    },
    computed: {
        authors() {
            const authorsStore = this.$store.state.authors;
            const authorsWithText = this.items
                .filter(item => authorsStore[item.author.id])
                .map(item => ({
                    ...authorsStore[item.author.id], 
                    authorText: item.author_text
                }));

            return authorsWithText;
        }
    },
};
</script>

<style lang="scss" scoped>
.dropdown-container::v-deep {
    @include border();
    border-radius: 25px;
    box-shadow: $bxs;

    .author-expander {

        .expand-row__button {
            justify-content: flex-start;
            gap: 0.5rem;
            flex-wrap: nowrap;
            font-size: 18px;
            @include spacing(padding, 3, (left, right));

            @include device(pad) {
                gap: 1rem;
                @include spacing(padding, 4, (left, right));
            }
            
            i.right-icon {
                margin-left: auto;
                margin-right: 0.25rem;
            }
        }

        .expand-row__content {
            background-color: $graybg;
            @include spacing(padding, 3, (left, right));

            @include device(pad) {
                @include spacing(padding, 4, (left, right));
            }

            p {
                @include font-size(16px,10px);}
        }

        .rich-text {
            @include spacing(padding, 4, (top, bottom));

            :first-child {
                margin-top: 0;
            }
            :last-child {
                margin-bottom: 0;
            }
        }

        &:last-child {
            .expand-row__content {
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
            }
        }
    }
}
</style>