<template>
    <div class="search">
        <Button
            :text="$translate('add_card', 'Lägg till kort')"
            size="md"
            background="green"
            class="open-search-button"
            @click="show = true"
        />

        <transition name="fade">
            <div
                v-if="show"
                class="search-open"
            >
                <div class="search-open__popup">
                    <input
                        ref="input"
                        v-model="query"
                        class="search-input"
                        type="text"
                        name="search"
                        :placeholder="$translate('search_cards', 'Sök efter kort')"
                    >
                    <div v-if="queryValid" class="search-result-wrapper">
                        <card-tile-list
                            v-if="cardList.length > 0"
                            :cards="cardList"
                            :display-as-column="true"
                            @toggle-card="$emit('toggle-card', $event)"
                        />
                        <div v-else class="no-results"> {{ $translate('no_results_found', 'Inga resultat hittades') }}</div>
                    </div>
                    <div v-if="showSuggestedCards">
                        <suggested-cards 
                            :display-as-column="true" 
                            :show-header="true" 
                            @toggle-card="$emit('toggle-card', $event)" 
                        />
                    </div>
                </div>

                <div
                    class="search-open__overlay"
                    @click="query = '', show = false"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import CardTileList from './CardTileList.vue';
import SuggestedCards from './SuggestedCards.vue';
export default {
    components: {
        CardTileList,
        SuggestedCards
    },
    data() {
        return {
            query: '',
            show: false
        };
    },
    computed: {
        cardList() {
            let cards = Object.values(this.$store.state.cards);

            return cards.filter(card => 
                card.data.title.toLowerCase().includes(this.query.toLowerCase()));
        },
        currentlySelectedCards() {
            return this.$route.query.card || [];
        },
        queryValid() {
            return this.query.length >= 3; 
        },
        showSuggestedCards() {
            return this.cardList.length === 0 || !this.queryValid;
        }
    },
    watch: {
        show() {
            if (this.show) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .search {
        position: relative;
        z-index: 2;
        .open-search-button {
            white-space: nowrap;
        }
        &-open {
            @include size(100vw,100vh);
            position: fixed;
            left: 0;
            top: 0;
            &__popup {
                @include device(mobile) {
                    width: 95%;
                }
                @include spacing(padding,4);
                max-width: 100%;
                width: 400px;
                background: $white;
                @include border;
                @include border-radius(20px);
                z-index: 1;
                position: absolute;
                left: 50%;
                top: 150px;
                transform: translateX(-50%);
                input {
                    width: 100%;
                    margin-bottom: 10px;
                }
                .search-result-wrapper {
                    > div {
                        max-height: 320px;
                        overflow: auto;
                    }
                    .no-results{
                        padding: 10px;
                        @include font-size(14px,8px);
                    }
                }
            }
            &__overlay {
                position: fixed;
                @include size(100%);
                top: 0;
                left: 0;
                background: rgba(0,0,0,.5);
            }
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>