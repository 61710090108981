<template>
    <div v-if="items.length > 0" class="disclosure-wrapper">
        <div 
            v-if="$validateText('disclosureButtonText')" 
            class="disclosure-button" 
            @click="isOpen = true"
        >
            <i class="fa-solid fa-info-circle" />
            {{ disclosureButtonText }}
        </div>
        <div v-if="isOpen" class="disclosure-container">
            <div class="disclosure-container__content">
                <div class="disclosure-container__content__header-wrapper">
                    <div 
                        v-if="$validateText('disclosureHeader')" 
                        class="disclosure-container__content__header-wrapper__header" 
                        v-html="$prismic.asHtml(disclosureHeader)" 
                    />
                    <i class="fa-solid fa-xmark" @click="isOpen = false" />
                </div>
                <div class="disclosure-container__content__expanders">
                    <expand
                        v-for="item in items"
                        :key="item.label"
                        :button="item.label"
                        :left-icon="item.icon"
                        class="expander"
                    >
                        <span class="rich-text" v-html="$prismic.asHtml(item.text)" />
                    </expand>
                </div>
            </div>
            <div
                class="disclosure-container__overlay"
                @click="isOpen = false"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false
        };
    },
    computed: {
        settings() {
            return this.$store.state.settings;
        },
        disclosureHeader() {
            return this.settings.important_disclosure_header;
        },
        disclosureButtonText() {
            return this.settings.important_disclosure_button;
        },
        settingsItems() {
            return this.settings.important_disclosure_rows;
        },
        items() {
            const rows = this.settingsItems
                .map(row => {
                    return {
                        label: row.label,
                        text: row.text,
                        icon: row.icon && `fa-solid fa-lg ${row.icon}`
                    };
                })
                .filter(item => this.$validateText(item.text) && this.$validateText(item.label));
            return rows;
        }
    },
};
</script>

<style lang="scss" scoped>
.disclosure-wrapper {
    width: 100%;
    text-align: right;

    .disclosure-button {
        @include font-size(14px);
        font-weight: bold;
        color: $green;
        @include spacing(margin,-4,top);
        @include spacing(padding,2,(bottom, right));
        &:hover {
            cursor: pointer;
        }
    }

    .disclosure-container {
        position: relative;

        &__content {
            text-align: left;
            border-radius: 20px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            height: 70vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            background: #fff;
            z-index: 2;

            @include device(pad) {
                width: 500px;
                height: 500px;
            }

            &__header-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include border(bottom, 3px,$green);
                @include spacing(padding,6,(left,right));
                @include spacing(padding,4,(bottom,top));

                &__header {
                    color: $green;
                    @include font-size(20px);
                }
                i {
                    @include font-size(20px);
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &__expanders {
                width: 100%;
                max-height: 100%;
                overflow-y: auto;
                overscroll-behavior: contain;
                scrollbar-width: thin;
                scrollbar-gutter: stable both-edges;
                @include spacing(padding,4,(left,right,bottom));
                @include spacing(padding,2,(top));

                .expander::v-deep {
                    .expand-row__button {
                        flex-wrap: nowrap;
                        gap: 10px;

                        i.left-icon {
                            color: $green;
                            transform: rotate(0);
                        }
                        i.right-icon {
                            margin-left: auto;
                        }
                    }
                    .expand-row__content {
                        .rich-text {
                            display: block;
                            @include spacing(padding,2,bottom);
                            
                            * {
                                @include font-size(14px);
                                &:first-child {
                                    @include spacing(margin,0,top);
                                }
                            }
                        }
                    }
                }
            }
        }

        &__overlay {
            background: #000;
            opacity: .25;
            width: 100vw;
            height: 100vh;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 1;
        }
    }
}
</style>