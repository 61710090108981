// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-18642c7e]{font-family:\"Open Sans\"}.font-header[data-v-18642c7e]{font-family:\"Hero\"}section .grid[data-v-18642c7e]{display:grid;grid-gap:80px;gap:80px;grid-template-columns:repeat(1,1fr);box-sizing:border-box}@media screen and (max-width:600px){section .grid[data-v-18642c7e]{margin-top:24px}}@media screen and (min-width:600px){section .grid[data-v-18642c7e]{margin-top:24px;margin-top:30px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
