<template>
    <div v-if="cardsToDisplay.length > 0" :class="['card-suggestions', {'column': displayAsColumn}]">
        <p v-if="displayHeader" class="card-suggestions__header">
            {{ $translate('compare_cards_suggested_cards', 'Ofta jämförda kort:') }}
        </p>
        <CardTileList :cards="cardsToDisplay" :display-as-column="displayAsColumn" @toggle-card="$emit('toggle-card', $event)" />
    </div>
</template>

<script>
import CardTileList from './CardTileList.vue';
export default {
    components: {
        CardTileList
    },
    props: {
        displayAsColumn: {
            type: Boolean,
            default: false
        },
        showHeader: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            suggestedCardsFromSettings: this.$store.state.settings.compare_cards_suggested_cards
        };
    },
    computed: {
        cardsToDisplay() {
            if (!this.suggestedCardsFromSettings) {
                return [];
            }
            const suggestedCardsIds = this.suggestedCardsFromSettings.map(card => card.card.id);
            const cardStore = Object.values(this.$store.state.cards);
            const suggestedCards = cardStore.filter(card => suggestedCardsIds.includes(card.id));

            return suggestedCards;
        },
        displayHeader() {
            return this.showHeader && this.cardsToDisplay.length > 0;
        }
    }
};
</script>

<style lang="scss" scoped>
.card-suggestions {
    width: 100%;
    @include device(pad) {
        // desktop view on the main compare page
        &:not(.column) {
            @include spacing(padding,1,(left,right));
            @include spacing(padding,4,(top,bottom));
            @include spacing(margin,-4,(top,bottom));
            overflow: hidden;
            overflow-x: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;   /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    // inside the search popup
    &.column {
        padding: 0;
    }
    &__header {
        font-weight: bold;
        @include font-size(14px,10px);
        @include spacing(margin, 1, (left,top,bottom));
    }
}
</style>