// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-24499c39]{font-family:\"Open Sans\"}.font-header[data-v-24499c39]{font-family:\"Hero\"}.wrapper-post[data-v-24499c39] .slices .section{padding:30px 20px}.wrapper-post[data-v-24499c39] .slices .section:first-child{margin-top:0}.wrapper-post[data-v-24499c39] .slices .section:not(.content-button) .section-inner{width:900px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
