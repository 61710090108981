<template>
    <div :class="['card-tile', {'column': displayAsColumn}]">
        <prismic-image
            class="image"
            :img="card.data.image"
            w="45"
        />
        <span :class="['title', {'long-title': card.data.title.length > 28}]">
            {{ card.data.title }}
        </span>
        <i :class="getIconClass()" />
    </div>
</template>

<script>
export default {
    props: {
        card: {
            type: Object,
            required: true
        },
        displayAsColumn: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currentlySelectedCards() {
            return this.$route.query.card || [];
        }
    },
    methods: {
        cardIsSelected() {
            return this.currentlySelectedCards.includes(this.card.id);
        },
        getIconClass() {
            return this.cardIsSelected() ? 'fa-regular fa-xmark fa-clear' : 'fa-solid fa-plus';
        }
    }
};
</script>

<style lang="scss" scoped>
.card-tile {
    width: 100%;
    min-width: 230px;
    height: 52px;
    @include flex(start);
    flex-wrap: nowrap;
    gap: 0.5rem;

    padding: 10px;
    @include border;
    border-radius: 10px;
    box-shadow: $bxs;
    cursor: pointer;
    transition: transform 0.2s;

    @include device(pad) {
        &:not(.column) {
            max-width: 250px;
        }
    }
    @include device(desktop) {
        &:not(.column) {
            max-width: 280px;
        }
    }
    &:hover {
        transform: scale(1.02);
        transition: transform 0.2s;
    }
    .image {
        height: 100%;
        max-width: 45px;
    }
    .title {
        flex: 1;
        font-weight: bold;
        font-size: 14px;
        white-space: wrap;
        &.long-title {
            // stops longer titles wrapping to 3 lines
            min-width: 151px;
        }
    }
    i:not(.fa-clear) {
        color: $green;
    }
}
</style>