<template>
    <div v-if="author" class="author-banner">
        <div>
            <prismic-image
                :img="author.data.image"
                w="65"
                class="author-image"
            />
        </div>
        <div class="author-editor-wrapper">
            <div class="author-editor">
                <nuxt-link :to="$prismic.linkResolver(author)">
                    <div v-html="writtenByAuthor" />
                </nuxt-link>

                <nuxt-link v-if="editor" :to="$prismic.linkResolver(editor)">
                    <div v-html="reviewedByEditor" />
                </nuxt-link>
            </div>
            <div class="last-updated-fact-checked">
                <div class="last-updated">
                    <i 
                        class="fa-regular fa-calendar-lines-pen"
                    />
                    {{ lastUpdated }}
                </div>
                <div v-if="editor" class="fact-checked">
                    <i 
                        class="fa-regular fa-shield-check"
                    />
                    <span>
                        {{ $translate('banner_fact_checked', 'Granskad') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: false,
            default: null
        }
    },
    computed: {
        author() {
            return this.$store.state.authors[this.document.data.post_author.id];
        },
        editor() {
            return this.$store.state.authors[this.document.data.editor.id];
        },
        lastUpdated() {
            return this.$format.date(this.document.last_publication_date, 'DATE_MED');
        },
        writtenByAuthor() {
            return this.$translate('by_author', 'Av {author}')
                .replace('{author}', `<span>${this.author.data.name}</span>`);
        },
        reviewedByEditor() {
            return this.$translate('author_box_reviewed_by_editor', 'Granskad av {editor}')
                .replace('{editor}', `<span>${this.editor.data.name}</span>`);
        }
    }
};
</script>
<style lang='scss' scoped>
    .author-banner {
        display: flex;
        gap: 0.25rem;
        align-items: center;
        @include spacing(margin, 7, top);
        @include font-size(16px, 8px);
        @include device(pad) {
            gap: 0.5rem;
        }

        .author-editor-wrapper::v-deep {
            display: flex;
            flex-direction: column;
            gap: 0.125rem;
            @include device(pad) {
                gap: 0.5rem;
            }

            .author-editor {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                gap: 0.125rem;
                @include device(pad) {
                    flex-direction: row;
                    column-gap: 0.75rem;
                    row-gap: 0.25rem;
                    @include spacing(margin, 0, bottom);
                }

                a {
                    @include font-size(14px, 8px);
                    @include device(pad) {
                        @include font-size(16px, 8px);
                    }
                    span {
                        text-decoration: underline;
                        font-weight: bold;
                    }
                }
                
            }
            .last-updated-fact-checked {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                @include font-size(14px, 8px);
                @include device(pad) {
                    @include font-size(16px, 8px);
                    gap: 0.75rem;
                }

                .last-updated {
                    display: flex;
                    align-items: center;
                    gap: 0.25rem;
                }
                .fact-checked {
                    display: flex;
                    align-items: center;
                    gap: 0.25rem;
                    padding: 0 0.35rem;
                    font-weight: normal;
                    border-radius: 0.25rem;
                    color: $green;
                    // TODO: change to existing color?
                    // $muted with less opacity currently
                    background-color: #8c98a41c;
                }
            }
        }
    }
</style>